<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div>


      <div class="grid30">
        <div class="div1">
          <h4>заказчик</h4>
          <router-link :to="'/counterparty/'+contract.customer.id">
            {{ contract.customer.shortName || contract.customer.fullName }}
          </router-link>
          <h4>подрядчик</h4>
          <a href="#">
            <router-link :to="'/counterparty/'+contract.supplier.id">
              {{ contract.supplier.shortName || contract.supplier.fullName }}
            </router-link>
          </a>
          <h4>предмет договора</h4>
          <p>{{ contract.theme }}</p>
          <br>
          <h4>шифр</h4>
          <p>{{ contract.cypherPrefix }}–{{ contract.cypherCounter }}</p>
        </div>

        <div class="div2">
          <h2>Общая информация</h2>

          <div>
            <h4>сумма договора</h4>
            <h3>{{ contract.contractSum | formatPrice }}</h3>
          </div>

          <div>
            <h4>дата начала и окончания</h4>
            <p>{{ contract.startDate | formatDate }} – {{ contract.endDate | formatDate }}</p>
          </div>

          <div>
            <h4>закон</h4>
            <p>{{ contract.purchaseLaw ? purchaseLawDict[contract.purchaseLaw] : '-' }}</p>
          </div>


          <div>
            <h4>этапов</h4>
            <p> {{ contract.stages ? contract.stages.length : '1' }}</p>
          </div>


          <div>
            <h4>Бумажный оригинал договора находится в архиве организации</h4>
            <p>{{ contract.originalInArchive ? 'Да' : 'Нет' }}</p>
          </div>


          <div>
            <h4>Проблемный</h4>
            <p>{{ contract.problematic ? 'Да' : 'Нет' }}</p>
          </div>


          <router-link to="files/Текст%20договора" class="btn outline">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 15H3V13H15V15ZM15 11H3V9H15V11ZM15 7H3V5H15V7ZM0 20L1.5 18.5L3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20V0L16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0L1.5 1.5L0 0V20Z"
                    fill="#3577BE"/>
            </svg>
            Текст договора
          </router-link>

        </div>
        <div class="div3">
          <h2>Финансы</h2>


          <div>
            <div>
              <h4>ндс</h4>
              {{ contract.ndsPercent }}%
            </div>
            <div>
              <h4>Аванс по оплате</h4>
              {{ contract.advanceSum | formatPrice }}
            </div>
          </div>

          <h4>Обеспечение исполнения контракта</h4>
          <template v-if="contract.contractGuaranteeNeeded">
            <p>{{ contract.contractGuaranteeType ? guaranteeTypeDict[contract.contractGuaranteeType] : '-' }}</p>
            <div>
              <div>
                <h4>Сумма обеспечения</h4>
                {{ contract.contractGuaranteeSum | formatPrice }}
              </div>
              <div>
                <h4>Комиссия</h4>
                {{ contract.contractGuaranteeCommission | formatPrice }}
              </div>
            </div>
          </template>
          <p v-else>-</p>
          <h4>Гарантийные обязательства</h4>
          <template v-if="contract.warrantyGuaranteeNeeded">
            <p>{{ contract.warrantyGuaranteeType ? guaranteeTypeDict[contract.warrantyGuaranteeType] : '-' }}</p>
            <div>
              <div>
                <h4>Дата окончания обязательств </h4>
                {{ contract.warrantyGuaranteeEndDate | formatDate }}
              </div>
              <div>
                <h4>Сумма обеспечения</h4>
                {{ contract.warrantyGuaranteeSum | formatPrice }}
              </div>
              <div>
                <h4>Комиссия</h4>
                {{ contract.warrantyGuaranteeCommission | formatPrice }}
              </div>
            </div>
          </template>
          <p v-else>-</p>


        </div>

        <div class="div4">
          <h2>Ответственные</h2>

          <h4>Руководитель проекта</h4>
          <p v-if="contract.offerExpert">
            <router-link :to="'/employee/' + contract.offerExpert.id + '/info'">{{ offerExpertName }}</router-link>
          </p>
          <p v-else>-</p>

          <h4>Ответственный за ведение</h4>
          <p v-if="contract.formalExpert">
            <router-link :to="'/employee/' + contract.formalExpert.id + '/info'">{{ formalExpertName }}</router-link>
          </p>
          <p v-else>-</p>

          <h4>контактные лица</h4>
          {{ contract.contactFIO }}
          <br/>
          {{ contract.contactPhone }}<br>
          <a href="#">{{ contract.contactEmail }}</a>
        </div>

        <div v-if="contract.url || contract.notification">
          <h2>Ссылки</h2>

<!--          <p><a href="#">Письма по договору</a></p>-->
          <p v-if="contract.url"><a :href="contract.url" target="_blank">Договор на zakupki.gov.ru</a></p>
          <p v-if="contract.notification">
            <router-link :to="'/tender/'+contract.notification.id+'/info?type=supmain%2Fnotifications'">Тендер
            </router-link>
          </p>

        </div>

        <div>
          <h2>Информация для сайта</h2>

          <h4>Описание проекта</h4>
          <p style="white-space: pre-line">{{ contract.description }}</p>

          <h4>Рынки</h4>
          <p>
                  <span v-for="(market, marketIndex) in contract.markets" :key="marketIndex+'market_'">
                    {{ supMarketDict[market] }}<template v-if="marketIndex + 1 !== contract.markets.length">,</template>
                  </span>
          </p>

          <h4>Технологии</h4>
          <p>
                  <span v-for="(tech, techIndex) in contract.technologies" :key="techIndex+'tech_'">
                    {{ technologiesDict[tech] }};
                  </span>
          </p>

          <h4>Участники</h4>
          <p>
                  <span v-for="(expert, expertIndex) in contract.participants" :key="expertIndex+'particip_'">
                    <router-link :to="'/employee/'+expert.id + '/info'">
                      {{ getExpertShortName(expert) }}<template v-if="expertIndex + 1 !== contract.participants.length">,</template>
                    </router-link>
                  </span>
          </p>

          <h4>Регионы исследования</h4>
          <p>
                  <span v-for="(region, regionIndex) in contract.regions" :key="regionIndex+'reg_'">
                    {{ region.regionName }}
                  </span>
          </p>

          <h4>отображение на сайте</h4>
          <p>{{ contract.forSite ? 'Да' : 'Нет' }}</p>

        </div>

        <div style="display: flex; flex-direction: column;">
          <h2>Примечания</h2>
          {{ contract.suppliersRestrictions }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  guaranteeTypeDict,
  purchaseLawDict,
  supMarketDict,
  technologiesDict
} from "@/modules/NSI";
import {dictToObject, getExpertShortName, loadDataToObject} from "@/modules/CommonUtils";


export default {
  name: "ContractInfoTab",
  props: ['contract'],
  data() {
    return {
      textDirEmpty: true,
      purchaseLawDict: dictToObject(purchaseLawDict),
      guaranteeTypeDict: dictToObject(guaranteeTypeDict),
      supMarketDict: dictToObject(supMarketDict),
      technologiesDict: dictToObject(technologiesDict),
    }
  },
  computed: {
    offerExpertName() {
      return getExpertShortName(this.contract.offerExpert)
    },
    formalExpertName() {
      return getExpertShortName(this.contract.formalExpert)
    }
  },
  methods: {
    loadData: loadDataToObject,
    getExpertShortName: getExpertShortName
  },
  async beforeMount() {
    //await this.loadData('/storage/getFilesList?containerId=' + this.contract.fileStorageContainerId + '&path=/Текст договора', 'contractTextDir', false)
    //this.textDirEmpty = this.contractTextDir.length === 0
  }
}
</script>

<style scoped>

</style>
